<template>
  <b-container fluid>
    <Header></Header>
    <b-row>
      <b-col class="my-3 my-md-5">
        <router-view/>
      </b-col>
    </b-row>
    <Footer></Footer>
  </b-container>
</template>

<script>

import Header from "@/view/layout/header/Header";
import Footer from "@/view/layout/footer/Footer";

export default {
  name: "Layout",
  components: {
    Footer, Header,
  },
  data: function () {
    return {counter: 0}
  },
  methods: {
    showToast() {
      const toastId = 'claim-' + this.counter;
      const vNodesMsg = this.$createElement(
          'div',
          [
            this.$createElement('div', {class: ['mb-3']}, '1 ETH for 131.142 DAI'),
            this.$createElement(
                'button',
                {
                  class: ['button-stroked'],
                  on: {click: this.$bvToast.hide(toastId)}
                },
                'Claim tokens'),
          ]
      )
      this.$bvToast.toast([vNodesMsg], {
        title: `Claim your token`,
        id: toastId,
        toaster: 'b-toaster-bottom-right',
        variant: 'claim',
        solid: true,
        appendToast: true,
        AutoHide: 5000000,
        noCloseButton: true,
      })
      this.counter++
    },
    hideToast(id) {
      this.$bvToast.hide('claim-' + id)
    }
  }

}
</script>

<style lang="scss">

</style>
