<template>
  <footer class="fixed-bottom" v-if="$store.state.ui.init">
    <b-row class="footer" align-v="center" align-h="between">
      <b-col>
        <span>&copy; 2021 LiquiFi.</span> <span>All rights reserved.</span>
      </b-col>
      <b-col class="social-icons">
        <a href="https://twitter.com/liquifiorg">
          <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6923 1.89525C19.1439 2.71111 18.4712 3.40659 17.674 3.98169C17.6819 4.13593 17.6858 4.31026 17.6858 4.50468C17.6858 5.58493 17.5284 6.66648 17.2134 7.74934C16.8985 8.8322 16.4175 9.8687 15.7703 10.8588C15.1232 11.8489 14.3523 12.7261 13.4577 13.4903C12.5631 14.2545 11.4877 14.8638 10.2316 15.3183C8.97547 15.7728 7.62925 16 6.19293 16C3.95131 16 1.887 15.3947 0 14.1841C0.33492 14.2214 0.656137 14.24 0.963651 14.24C2.83634 14.24 4.50895 13.6631 5.98149 12.5092C5.10834 12.4932 4.32636 12.2243 3.63557 11.7027C2.94477 11.181 2.46938 10.5144 2.2094 9.70285C2.46637 9.75181 2.72 9.77629 2.97028 9.77629C3.33054 9.77629 3.68503 9.7293 4.03375 9.6353C3.10193 9.44864 2.32891 8.98442 1.71468 8.24266C1.10047 7.50091 0.79337 6.64479 0.79337 5.6743V5.62428C1.36532 5.94104 1.9754 6.10953 2.62361 6.12975C2.0716 5.76167 1.63395 5.28167 1.31064 4.68977C0.987315 4.09787 0.82565 3.45732 0.82565 2.76814C0.82565 2.04128 1.00775 1.36444 1.37194 0.737615C2.38528 1.98142 3.61314 2.97551 5.05554 3.71988C6.49796 4.46427 8.04541 4.87768 9.69787 4.96011C9.62739 4.66632 9.59208 4.35973 9.59196 4.04035C9.59196 2.92519 9.9865 1.97301 10.7756 1.18381C11.5647 0.394602 12.5167 0 13.6316 0C14.7989 0 15.7821 0.425125 16.5812 1.27537C17.4942 1.09448 18.3491 0.767285 19.1459 0.293792C18.839 1.26008 18.2474 2.00551 17.3714 2.53008C18.1766 2.43438 18.9502 2.22277 19.6923 1.89525H19.6923Z" fill="#80808F"/>
          </svg>
        </a>
        <a href="mailto:hello@liquifi.org">
          <svg width="20" height="16"  viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path  fill="#80808F" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" class=""></path>
          </svg>
        </a>
        <!--a href="">
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6924 0C14.5188 0 15.2254 0.293403 15.8122 0.880208C16.399 1.46701 16.6924 2.17361 16.6924 3V13C16.6924 13.8264 16.399 14.533 15.8122 15.1198C15.2254 15.7066 14.5188 16 13.6924 16H11.734V9.80208H13.807L14.1195 7.38542H11.734V5.84375C11.734 5.45486 11.8156 5.16319 11.9788 4.96875C12.142 4.77431 12.4597 4.67708 12.932 4.67708L14.2028 4.66667V2.51042C13.7653 2.44792 13.1472 2.41667 12.3486 2.41667C11.4042 2.41667 10.649 2.69444 10.083 3.25C9.51704 3.80556 9.23405 4.59028 9.23405 5.60417V7.38542H7.15072V9.80208H9.23405V16H3.69238C2.86599 16 2.1594 15.7066 1.57259 15.1198C0.985786 14.533 0.692383 13.8264 0.692383 13V3C0.692383 2.17361 0.985786 1.46701 1.57259 0.880208C2.1594 0.293403 2.86599 0 3.69238 0H13.6924Z" fill="#80808F"/>
          </svg>
        </a-->
        <!--a href="">
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.69038 5.3322C7.22139 5.3322 6.02258 6.53101 6.02258 8C6.02258 9.46899 7.22139 10.6678 8.69038 10.6678C10.1594 10.6678 11.3582 9.46899 11.3582 8C11.3582 6.53101 10.1594 5.3322 8.69038 5.3322ZM16.6918 8C16.6918 6.89525 16.7018 5.80051 16.6398 4.69777C16.5777 3.4169 16.2855 2.28014 15.3489 1.3435C14.4102 0.404868 13.2755 0.114672 11.9946 0.0526304C10.8899 -0.0094115 9.79513 0.000595291 8.69238 0.000595291C7.58764 0.000595291 6.4929 -0.0094115 5.39015 0.0526304C4.10929 0.114672 2.97252 0.40687 2.03589 1.3435C1.09725 2.28214 0.807055 3.4169 0.745013 4.69777C0.682971 5.80252 0.692978 6.89725 0.692978 8C0.692978 9.10275 0.682971 10.1995 0.745013 11.3022C0.807055 12.5831 1.09925 13.7199 2.03589 14.6565C2.97452 15.5951 4.10929 15.8853 5.39015 15.9474C6.4949 16.0094 7.58964 15.9994 8.69238 15.9994C9.79713 15.9994 10.8919 16.0094 11.9946 15.9474C13.2755 15.8853 14.4122 15.5931 15.3489 14.6565C16.2875 13.7179 16.5777 12.5831 16.6398 11.3022C16.7038 10.1995 16.6918 9.10475 16.6918 8ZM8.69038 12.1048C6.41885 12.1048 4.58561 10.2715 4.58561 8C4.58561 5.72847 6.41885 3.89523 8.69038 3.89523C10.9619 3.89523 12.7952 5.72847 12.7952 8C12.7952 10.2715 10.9619 12.1048 8.69038 12.1048ZM12.9633 4.68576C12.4329 4.68576 12.0046 4.25747 12.0046 3.72711C12.0046 3.19675 12.4329 2.76847 12.9633 2.76847C13.4936 2.76847 13.9219 3.19675 13.9219 3.72711C13.9221 3.85305 13.8974 3.97778 13.8493 4.09416C13.8011 4.21054 13.7305 4.31628 13.6415 4.40533C13.5524 4.49438 13.4467 4.56499 13.3303 4.61311C13.2139 4.66123 13.0892 4.68592 12.9633 4.68576Z" fill="#80808F"/>
          </svg>
        </a-->
      </b-col>
    </b-row>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss">
  .footer {
    background-color: $color-gray-300;
    margin-top: 30px;
    height: 72px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .social-icons {
    justify-content: flex-end;
    svg { margin-left: 16px}
  }

</style>
