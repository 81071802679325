<template>
  <header class="fixed-top" v-if="$store.state.ui.init">
    <b-row class="header" align-v="center" align-h="between">
      <b-col class="logo" xs="12">
        <svg width="85" height="21" viewBox="0 0 85 21" fill="none" xmlns="http://www.w3.org/2000/svg"
             clickable
             v-on:click="openWindow('landing')">
          <path d="M26.5654 2.6366H29.3696V16.8268H26.5654V2.6366Z" fill="#05A69E"/>
          <path d="M32.9551 5.75427H35.7592V16.8267H32.9551V5.75427Z" fill="#05A69E"/>
          <path
              d="M43.7791 5.59387C45.4375 5.59387 46.6573 6.33324 47.3363 7.19683V5.75417H50.1545V20.5054H47.3363V15.3098C46.901 15.8467 46.3488 16.2771 45.7219 16.5681C45.095 16.8591 44.41 17.003 43.719 16.9889C40.9149 16.9889 38.6235 14.6706 38.6235 11.2724C38.6235 7.87408 40.9209 5.59387 43.7791 5.59387ZM44.398 8.03237C42.9599 8.03237 41.4817 9.13241 41.4817 11.2724C41.4817 13.4123 42.9599 14.5504 44.398 14.5504C45.8361 14.5504 47.3363 13.4303 47.3363 11.2924C47.3363 9.15445 45.8582 8.03237 44.398 8.03237Z"
              fill="#05A69E"/>
          <path
              d="M64.177 16.8267H61.3588V15.4241C60.6598 16.3639 59.44 16.963 58.062 16.963C55.4842 16.963 53.6255 15.2839 53.6255 12.2463V5.75427H56.4296V11.8515C56.4296 13.6308 57.407 14.5906 58.8872 14.5906C60.4054 14.5906 61.3649 13.6308 61.3649 11.8515V5.75427H64.183L64.177 16.8267Z"
              fill="#05A69E"/>
          <path d="M67.7666 5.75427H70.5707V16.8267H67.7666V5.75427Z" fill="#05A69E"/>
          <path
              d="M74.4566 8.05254H73.1968V5.75429H74.4566V5.20528C74.4566 2.48626 75.9949 1.22593 79.1115 1.30608V3.65442C77.7535 3.61434 77.2948 4.05516 77.2948 5.29545V5.75429H79.2377V8.05254H77.2948V16.8267H74.4566V8.05254Z"
              fill="#1B283F"/>
          <path d="M81.8833 5.75427H84.6874V16.8267H81.8833V5.75427Z" fill="#1B283F"/>
          <path
              d="M7.54714 8.49338C8.49625 8.49338 9.26566 7.75957 9.26566 6.85436C9.26566 5.94915 8.49625 5.21533 7.54714 5.21533C6.59802 5.21533 5.82861 5.94915 5.82861 6.85436C5.82861 7.75957 6.59802 8.49338 7.54714 8.49338Z"
              fill="#05A69E"/>
          <path
              d="M83.282 4.27549C84.2311 4.27549 85.0005 3.54167 85.0005 2.63646C85.0005 1.73125 84.2311 0.997437 83.282 0.997437C82.3329 0.997437 81.5635 1.73125 81.5635 2.63646C81.5635 3.54167 82.3329 4.27549 83.282 4.27549Z"
              fill="#1B283F"/>
          <path
              d="M34.3533 4.27549C35.3024 4.27549 36.0718 3.54167 36.0718 2.63646C36.0718 1.73125 35.3024 0.997437 34.3533 0.997437C33.4042 0.997437 32.6348 1.73125 32.6348 2.63646C32.6348 3.54167 33.4042 4.27549 34.3533 4.27549Z"
              fill="#00A69E"/>
          <path
              d="M2.83817 10.0686V0.997803H0V12.9278C0 15.6108 1.4982 16.8731 4.53065 16.827H8.79691V14.4667H4.54467C3.2728 14.4787 2.83817 14.0299 2.83817 12.8236V10.0686Z"
              fill="#1B283F"/>
          <path
              d="M10.7619 8.0525V16.8267H13.6V4.89868C13.6 2.21773 12.1038 0.9554 9.06939 0.997477H4.80713V3.35984H9.05737C10.3292 3.34782 10.7619 3.79665 10.7619 5.00087V8.0525Z"
              fill="#05A69E"/>
        </svg>
      </b-col>
      <b-col class="menu">
        <b-row class="navbar flex-nowrap" align-h="end" align-v="center">
          <b-col class="navbar-item navbar-item-button d-flex justify-content-end" v-if="$store.state.web3.isInjected" xs="12">
            <div class="button-group" :class="{'two-button': getWalletNetwork, 'button-note' : isClaim}" id="wallet-data">
              <button class="button-small">
                <img :src="getWalletLogo">{{ getWalletShortName }}
              </button>
              <button class="button-small text-capitalize" v-if="getWalletNetwork">
                {{ getWalletNetwork }}
              </button>
            </div>

            <b-popover target="wallet-data"
                       ref="walletData"
                       triggers="click blur"
                       placement="bottom"
                       custom-class="wallet-data">
              <div class="wallet-info">
                <b-form-row>
                  <b-col class="mt-auto">
                    <div class="caption">
                      You wallet
                    </div>
                    <div>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.66667 10.6667V5.33333C6.66667 4.97971 6.80714 4.64057 7.05719 4.39052C7.30724 4.14048 7.64638 4 8 4H14V3.33333C14 2.6 13.4 2 12.6667 2H3.33333C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333V12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V12H8C7.64638 12 7.30724 11.8595 7.05719 11.6095C6.80714 11.3594 6.66667 11.0203 6.66667 10.6667Z"
                            fill="#1B283F"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8.66667 5.33337C8.3 5.33337 8 5.63337 8 6.00004V10C8 10.3667 8.3 10.6667 8.66667 10.6667H14.6667V5.33337H8.66667ZM9.66667 8.00004C9.66667 8.55337 10.1133 9.00004 10.6667 9.00004C11.22 9.00004 11.6667 8.55337 11.6667 8.00004C11.6667 7.44671 11.22 7.00004 10.6667 7.00004C10.1133 7.00004 9.66667 7.44671 9.66667 8.00004Z"
                              fill="#80808F"/>
                      </svg>
                      <span class="ml-2">{{ getWalletShortName }}</span>
                    </div>
                  </b-col>
                  <b-col class="text-right">
                    <b-button size="min" variant="gray" class="my-2" v-clipboard="$store.state.web3.coinbase">
                      <b-icon-files></b-icon-files>
                      Copy
                    </b-button>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col class="mt-auto">
                    <div class="caption mt-3">
                      Connected with
                    </div>
                    <div>
                      <img :src="getWalletLogo">
                      <span class="ml-2">{{ getWalletName }}</span>
                    </div>
                  </b-col>
                </b-form-row>
              </div>
              <div class="wallet-action my-2">
                <div class="wallet-action-item"
                     v-on:click="openWindow('etherscanWallet')"
                     clickable>
                  View on Etherscan
                </div>
                <!--                  <div class="wallet-action-item" clickable>-->
                <!--                    Change wallet-->
                <!--                  </div>-->
                <div class="wallet-action-item" v-on:click="handleLogout" clickable>
                  Disconnect wallet
                </div>
              </div>

              <template v-if="isClaim">
                <hr>
                <b-form-row class="mx-4 mb-2 text-primary" clickable v-on:click="goToLqf()">
                  <b-col class="col-auto">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.5"
                            d="M10.0003 1.6665C5.41699 1.6665 1.66699 5.4165 1.66699 9.99984C1.66699 14.5832 5.41699 18.3332 10.0003 18.3332C14.5837 18.3332 18.3337 14.5832 18.3337 9.99984C18.3337 5.4165 14.5837 1.6665 10.0003 1.6665ZM10.0003 16.6665C6.32533 16.6665 3.33366 13.6748 3.33366 9.99984C3.33366 6.32484 6.32533 3.33317 10.0003 3.33317C13.6753 3.33317 16.667 6.32484 16.667 9.99984C16.667 13.6748 13.6753 16.6665 10.0003 16.6665Z"
                            fill="#1BC5BD"/>
                      <path
                          d="M10.8337 6.6665L10.6465 11.06H9.35416L9.16699 6.6665H10.8337ZM10.798 11.7968V13.3332H9.21156V11.7968H10.798Z"
                          fill="#1BC5BD"/>
                    </svg>
                  </b-col>
                  <b-col>
                    Claim LQF tokens
                  </b-col>
                </b-form-row>

              </template>

              <div class="wallet-transaction" v-if="showTransactions">
                <div class="caption mx-4 pt-3">
                  Current transactions
                </div>
                <b-form-row class="wallet-transaction-item" clickable>
                  <b-col>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.666992 9.00008C0.666992 4.39758 4.39783 0.666748 9.00033 0.666748C13.6028 0.666748 17.3337 4.39758 17.3337 9.00008C17.3337 13.6026 13.6028 17.3334 9.00033 17.3334C4.39783 17.3334 0.666992 13.6026 0.666992 9.00008ZM9.00033 2.33341C7.23222 2.33341 5.53652 3.03579 4.28628 4.28604C3.03604 5.53628 2.33366 7.23197 2.33366 9.00008C2.33366 10.7682 3.03604 12.4639 4.28628 13.7141C5.53652 14.9644 7.23222 15.6667 9.00033 15.6667C10.7684 15.6667 12.4641 14.9644 13.7144 13.7141C14.9646 12.4639 15.667 10.7682 15.667 9.00008C15.667 7.23197 14.9646 5.53628 13.7144 4.28604C12.4641 3.03579 10.7684 2.33341 9.00033 2.33341Z"
                            fill="#B5B5C3"/>
                      <path
                          d="M8.16732 4.8335V8.16683H4.83398V9.8335H8.16732V13.1668H9.83398V9.8335H13.1673V8.16683H9.83398V4.8335H8.16732Z"
                          fill="#B5B5C3"/>
                    </svg>
                  </b-col>
                  <b-col>
                    Add 0.01 ETH and 4.97 DAI
                  </b-col>
                  <b-col>
                    <b-spinner variant="info" style="width: 1.5rem; height: 1.5rem;" label="Spinning"></b-spinner>
                  </b-col>
                </b-form-row>
                <b-form-row class="wallet-transaction-item" clickable>
                  <b-col>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.5"
                            d="M9.00033 0.666748C4.41699 0.666748 0.666992 4.41675 0.666992 9.00008C0.666992 13.5834 4.41699 17.3334 9.00033 17.3334C13.5837 17.3334 17.3337 13.5834 17.3337 9.00008C17.3337 4.41675 13.5837 0.666748 9.00033 0.666748ZM9.00033 15.6667C5.32533 15.6667 2.33366 12.6751 2.33366 9.00008C2.33366 5.32508 5.32533 2.33341 9.00033 2.33341C12.6753 2.33341 15.667 5.32508 15.667 9.00008C15.667 12.6751 12.6753 15.6667 9.00033 15.6667Z"
                            fill="#B5B5C3"/>
                      <path
                          d="M11.4997 6.50008V4.41675L14.4163 7.33341L11.4997 10.2501V8.16675H8.16634V6.50008H11.4997Z"
                          fill="#B5B5C3"/>
                      <path
                          d="M3.58301 10.6667L6.49967 7.75008V9.83342H9.83301V11.5001H6.49967V13.5834L3.58301 10.6667Z"
                          fill="#B5B5C3"/>
                    </svg>
                  </b-col>
                  <b-col>
                    Swap 1 ETH for 495 DAI
                  </b-col>
                  <b-col>
                    <b-spinner variant="info" style="width: 1.5rem; height: 1.5rem;" label="Spinning"></b-spinner>
                  </b-col>
                </b-form-row>
                <b-form-row class="wallet-transaction-item" clickable>
                  <b-col>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.5"
                            d="M9.00033 0.666748C4.40033 0.666748 0.666992 4.40008 0.666992 9.00008C0.666992 13.6001 4.40033 17.3334 9.00033 17.3334C13.6003 17.3334 17.3337 13.6001 17.3337 9.00008C17.3337 4.40008 13.6003 0.666748 9.00033 0.666748ZM9.00033 15.6667C5.32533 15.6667 2.33366 12.6751 2.33366 9.00008C2.33366 5.32508 5.32533 2.33341 9.00033 2.33341C12.6753 2.33341 15.667 5.32508 15.667 9.00008C15.667 12.6751 12.6753 15.6667 9.00033 15.6667Z"
                            fill="#B5B5C3"/>
                      <path d="M4.83301 9.83342V8.16675H13.1663V9.83342H4.83301Z" fill="#B5B5C3"/>
                    </svg>
                  </b-col>
                  <b-col>
                    Remove 3.11 DAI and 0.00631 ETH
                  </b-col>
                  <b-col>
                    <b-icon-x-circle-fill variant="danger"></b-icon-x-circle-fill>
                  </b-col>
                </b-form-row>
                <b-form-row class="wallet-transaction-item" clickable>
                  <b-col>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M4.83301 11.6527V10.1738H7.99967V11.6527H4.83301ZM10.9821 5.66675C11.8242 5.66675 12.5377 5.96018 13.1225 6.54703C13.7073 7.13388 13.9997 7.83811 13.9997 8.65971C13.9997 9.4813 13.7073 10.1855 13.1225 10.7724C12.5377 11.3592 11.8242 11.6527 10.9821 11.6527H10.9997V13.1667L8.75406 10.9132L10.9997 8.65971V10.1738H11.1927C11.5903 10.1738 11.9353 10.0271 12.2277 9.73365C12.5201 9.44022 12.6663 9.08224 12.6663 8.65971C12.6663 8.23717 12.5201 7.87919 12.2277 7.58576C11.9353 7.29234 11.5903 7.14562 11.1927 7.14562H4.83301V5.66675H10.9821Z"
                          fill="#B5B5C3"/>
                      <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.00033 15.6667C12.6822 15.6667 15.667 12.682 15.667 9.00008C15.667 5.31818 12.6822 2.33341 9.00033 2.33341C5.31843 2.33341 2.33366 5.31818 2.33366 9.00008C2.33366 12.682 5.31843 15.6667 9.00033 15.6667ZM9.00033 17.3334C13.6027 17.3334 17.3337 13.6025 17.3337 9.00008C17.3337 4.39771 13.6027 0.666748 9.00033 0.666748C4.39795 0.666748 0.666992 4.39771 0.666992 9.00008C0.666992 13.6025 4.39795 17.3334 9.00033 17.3334Z"
                            fill="#B5B5C3"/>
                    </svg>
                  </b-col>
                  <b-col>
                    Wrap 1 ETH to WETH
                  </b-col>
                  <b-col>
                    <b-icon-check-circle-fill variant="primary"></b-icon-check-circle-fill>
                  </b-col>
                </b-form-row>
                <b-form-row class="mx-2 my-2">
                  <b-col class="text-right">
                    <b-button size="min" variant="gray" class="my-2">
                      Clear list
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button size="min" variant="gray" class="my-2">
                      View all transactions
                    </b-button>
                  </b-col>
                </b-form-row>
              </div>

            </b-popover>

          </b-col>

          <b-col class="navbar-item navbar-item-button d-flex justify-content-end" v-else>
            <button class="button-stroked button-small" id="wallet">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6 2V4H4V6H2V12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H12.6667C13.4067 14 14 13.4067 14 12.6667V12H8C7.64638 12 7.30724 11.8595 7.05719 11.6095C6.80714 11.3594 6.66667 11.0203 6.66667 10.6667V5.33333C6.66667 4.97971 6.80714 4.64057 7.05719 4.39052C7.30724 4.14048 7.64638 4 8 4H14V3.33333C14 2.97971 13.8595 2.64057 13.6095 2.39052C13.3594 2.14048 13.0203 2 12.6667 2H6Z"
                    fill="#05A69E"/>
                <path d="M2 2V0H3.33333V2H5.33333V3.33333H3.33333V5.33333H2V3.33333H0V2H2Z" fill="#05A69E"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8 5.33334V10.6667H14.6667V5.33334H8ZM11.6667 8.00001C11.6667 7.44668 11.22 7.00001 10.6667 7.00001C10.1133 7.00001 9.66667 7.44668 9.66667 8.00001C9.66667 8.55334 10.1133 9.00001 10.6667 9.00001C11.22 9.00001 11.6667 8.55334 11.6667 8.00001Z"
                      fill="#8DE2DE"/>
              </svg>
              Connect wallet
            </button>
            <b-popover target="wallet"
                       ref="wallet"
                       triggers="click focus"
                       placement="bottom"
                       custom-class="wallet-list">
              <div v-for="wallet in walletList" :key="wallet.id"
                   v-on:click="handleLogin(wallet)"
                   class="wallet-list-item mt-2" clickable>
                <img :src=wallet.icon width="22" height="20">
                {{ wallet.name }}
              </div>
            </b-popover>

          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div md="auto" class="menu-trigger" @click="onMenuToggle">
      <svg v-if="!isMenuOpen" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.600098 7.9012C0.600098 8.45348 1.04781 8.9012 1.6001 8.9012H16.4001C16.9524 8.9012 17.4001 8.45348 17.4001 7.9012V7.61173C17.4001 7.05945 16.9524 6.61173 16.4001 6.61173H1.6001C1.04781 6.61173 0.600098 7.05945 0.600098 7.61173V7.9012ZM0.600098 13.6249C0.600098 14.1772 1.04781 14.6249 1.6001 14.6249H16.4001C16.9524 14.6249 17.4001 14.1772 17.4001 13.6249V13.3354C17.4001 12.7831 16.9524 12.3354 16.4001 12.3354H1.6001C1.04781 12.3354 0.600098 12.7831 0.600098 13.3354V13.6249ZM1.6001 0.888062C1.04781 0.888062 0.600098 1.33578 0.600098 1.88806V2.17753C0.600098 2.72981 1.04781 3.17753 1.6001 3.17753H16.4001C16.9524 3.17753 17.4001 2.72981 17.4001 2.17753V1.88806C17.4001 1.33578 16.9524 0.888062 16.4001 0.888062H1.6001Z"
            fill="#B5B5C3"/>
      </svg>
      <svg v-if="isMenuOpen" width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5.63623" y="16.9498" width="16" height="2" rx="1" transform="rotate(-45 5.63623 16.9498)"
              fill="#B5B5C3"/>
        <rect x="7.05029" y="5.63623" width="16" height="2" rx="1" transform="rotate(45 7.05029 5.63623)"
              fill="#B5B5C3"/>
      </svg>
    </div>

  </header>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {WEB_3_LOGIN, WEB_3_LOGOUT} from "@/store/modules/web3.module";
import connectors from "@/helpers/connectors.json"
import {INIT_UI} from "@/store/modules/ui.module";
import {getLSConnector} from "@/helpers/utils";
import {HTTP_GET_LQF_DATA} from "@/store/modules/governance.module";

export default {
  name: "Header",
  // async mounted() {
    // await this.httpGetLqfData()
  // },
  methods: {
    goToLqf(){
      if(this.$route.name !== 'lqf-tokens'){
        this.$router.push('/governance/lqf-tokens')
      }
    },
    openWindow(type) {
      switch (type) {
        case 'etherscanWallet':
          window.open(this.getEtherscanLink, '_blank')
          break
        case 'landing':
          window.open('https://liquifi.org', '_blank')
          break
      }
    },
    onMenuToggle: function () {
      if (this.isMobile) {
        this.isMenuOpen = !this.isMenuOpen;
        let menuHeight = window.innerHeight - 70;
        let elem = document.querySelector('.menu');
        if (elem.style.height === menuHeight + 'px') {
          elem.style.height = '0';
        } else {
          elem.style.height = menuHeight + 'px';
        }
      }
    },
    goToPage(path) {
      this.onMenuToggle();
      this.$router.push(path)
    },
    async handleLogin(connector) {
      this.$refs.wallet.doClose()
      await this.web3Login(connector.id)
      // await this.initUi()
      window.location.reload()
    },
    handleLogout() {
      this.web3Logout()
    },
    ...mapActions([
      WEB_3_LOGIN,
      WEB_3_LOGOUT,
      INIT_UI,
      HTTP_GET_LQF_DATA,
    ])
  },
  computed: {
    ...mapGetters([
      'getWalletShortName',
      'getWalletNetwork',
      'getEtherscanLink'
    ]),
    isMobile() {
      return window.innerWidth <= 970;
    },
    getWalletLogo() {
      const connector = getLSConnector()
      return this.walletList[connector].icon || ''
    },
    getWalletName() {
      const connector = getLSConnector();
      return this.walletList[connector].name || '';
    },
    isClaim(){
      return [4, 6].includes(this.$store.state.governance.lqf_minting_step)
    }
  },
  data: function () {
    return {
      showTransactions: false,
      walletList: connectors,
      isMenuOpen: false,
    }
  },
}
</script>

<style lang="scss">
  header {
    width: 100%;
    box-sizing: border-box;
    background-color: $white;
  }

  .header {
    background-color: $white;
    height: 72px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .logo {
    max-height: 30px;
  }

  .menu {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .navbar-item {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    position: relative;
    white-space: nowrap;

    #wallet-data {
      justify-content: flex-end;
    }

    #wallet {
      justify-content: flex-end;
    }


    &:not(.navbar-item-button) {
      margin-right: 40px;

      &.active {
        color: $color-primary-dark
      }

      &:hover:before, &.active:before {
        content: " ";
        background-color: $color-primary-dark;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

  }

  .menu-trigger {
    display: none;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
  }

  @media screen and(max-width: 970px) {
    .menu {
      height: 0;
      position: absolute !important;
      top: 71px;
      left: 0;
      width: 100%;
      overflow: hidden;
      background-color: $white;
      transition: all 0.3s ease-out;
    }

    .row.navbar {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
    }

    .navbar-item {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      width: 100%;

      &:last-of-type {
        margin-top: 24px
      }

      &:not(.navbar-item-button) {
        margin: 5px 15px;
      }

      &:active:before {
        content: "";
        background-color: $color-primary-dark;
        height: 12px;
        width: 12px;
        border-radius: 6px;

      }
    }
    .menu-trigger {
      display: block;
    }
  }

</style>
